import axios from "axios";      

export default axios.create({
    // baseURL: "http://172.16.10.240:8070", // new kiran local
    // baseURL : "http://172.16.114.147:8070",    // snehta  local
    //  baseURL : "http://172.16.10.213:8070",      // new subash local
    //  baseURL : "https://qaapi.wehouse.in"  , // qa
    //  baseURL :  "https://prodapi.wehouse.in"   ,   //prod
    // baseURL:"http://3.108.246.235:8070",
    baseURL:"https://api.wehouse.in",   //production server api
    // baseURL:"http://172.16.10.213:8070",

    headers: {
        "Content-Type": "application/json"
    }           
})
