import { Radio, Result, Tooltip, Upload } from "antd"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStatusListType } from "../../../../../Features/Saga/getStatusList"
import { blockInvalidChar } from "../../../../../Utilities/helpers"
import "./CustomerDetails.css"
import { BsUpload } from "react-icons/bs"
import {
    addProjectService,
    getProjectByCustomerId,
    updateProjectService
} from "../../../../../Service/SeCustomersServices"
import { toast } from "react-toastify"
import { BiLeftArrowAlt } from "react-icons/bi"
import { GETPROJECTTYPELIST } from "../../../../../Features/Saga/getProjectTypeList"

function ProjectDetails({
    toggleHandler,
    handleTabs,
    type,
    setCustomerIdFromApi,
    customerIdFromApi,
    projectDetailsForPayments,
    newLead
}) {
    console.log("project screen details", type, newLead)
    const [projectDetails, setProjectDetails] = useState({
        projectName: "",
        contractType: "",
        projectLocation: "",
        pinCode: "",
        city: "",
        projectType: "",
        noOfFloors: null,
        plotArea: "",
        slabArea: "",
        penthouse: "No",
        penthouseSlabArea: "",
        buildArea: "",
        totalCost: "",
        permissions: ["No"],
        status: "Draft",
        projectLatitude: "",
        projectLongitude: ""
    })
    const [errorMsg, setErrorMsg] = useState({
        projectName: "",
        contractType: "",
        projectLocation: "",
        pinCode: "",
        city: "",
        projectType: "",
        noOfFloors: null,
        plotArea: "",
        slabArea: "",
        penthouse: "No",
        penthouseSlabArea: "",
        buildArea: "",
        totalCost: "",
        permissions: ["No"],
        status: "Draft",
        projectLatitude: "",
        projectLongitude: ""
    })
    const [floorOptions, setFloorOptions] = useState([])

    // getting data from redux store:
    const dispatch = useDispatch()
    const statusList = useSelector((state) => state.getStatusListSlice.status)
    const projectTypeList = useSelector((state) => state.dropDownListSlice.projectTypeList)
    console.log("statusList::", statusList, projectTypeList)

    const ProjectHandlerChange = (e) => {
        const { name, value } = e.target
        console.log("name", e.target)
        setErrorMsg((prev) => ({ ...prev, [name]: "" }))
        if (name === "government") {
            let tempData = projectDetails?.permissions
            if (name === "government") {
                tempData[0] = value
                console.log("tempData", tempData)
                setProjectDetails((prevState) => ({ ...prevState, permissions: [...tempData] }))
            }
            // else {
            //     tempData[1] = value;
            //     setProjectDetails((prevState) => ({ ...prevState, permissions: [...tempData] }))
            // }
            // setProjectDetails((prevState)=>({...prevState,permissions:[...prevState.permissions,{[name]:value}]}));
        } else {
            if (name === "pinCode") {
                setProjectDetails((prevState) => ({ ...prevState, [name]: value.slice(0, 6) }))
            } else {
                setProjectDetails((prevState) => ({
                    ...prevState,
                    [name]: value
                }))
            }
        }
    }

    const fieldValidations = () => {
        let isValid = true
        if (!projectDetails?.projectName) {
            setErrorMsg((prev) => ({ ...prev, projectName: "Project name is required" }))
            isValid = false
        }
        if (!projectDetails?.contractType) {
            setErrorMsg((prev) => ({ ...prev, contractType: "Contract type is required" }))

            isValid = false
        }
        if (!projectDetails?.projectLocation) {
            setErrorMsg((prev) => ({ ...prev, projectLocation: "Project Location is required" }))
            isValid = false
        }
        if (!projectDetails?.projectLatitude) {
            setErrorMsg((prev) => ({ ...prev, projectLatitude: "Project Latitude is required" }))
            isValid = false
        }
        if (!projectDetails?.projectLongitude) {
            setErrorMsg((prev) => ({ ...prev, projectLongitude: "Project Longitude is required" }))
            isValid = false
        }
        if (!projectDetails?.pinCode || !projectDetails?.pinCode.length === 6) {
            setErrorMsg((prev) => ({ ...prev, pinCode: "Pin code  is required" }))
            isValid = false
        }
        if (!projectDetails?.city) {
            setErrorMsg((prev) => ({ ...prev, city: "City is required" }))
            isValid = false
        }
        if (!projectDetails?.projectType) {
            setErrorMsg((prev) => ({ ...prev, projectType: "Contract type is required" }))
            isValid = false
        }
        if (!projectDetails?.projectType) {
            setErrorMsg((prev) => ({ ...prev, projectType: "Project type is required" }))
            isValid = false
        } else if (projectDetails?.projectType === "Others") {
            if (!projectDetails?.noOfFloors) {
                setErrorMsg((prev) => ({ ...prev, noOfFloors: "No of floors is required" }))
                isValid = false
            } else if (!(projectDetails.noOfFloors > 0 && projectDetails.noOfFloors < 5)) {
            }
        } else {
            setErrorMsg((prev) => ({ ...prev, noOfFloors: "" }))
        }
        if (!projectDetails?.plotArea) {
            setErrorMsg((prev) => ({ ...prev, plotArea: "Plot Area is required" }))
            isValid = false
        }
        if (!projectDetails?.slabArea) {
            setErrorMsg((prev) => ({ ...prev, slabArea: "Slab Area is required" }))
            isValid = false
        }
        if (projectDetails?.penthouse === "yes") {
            if (!projectDetails?.penthouseSlabArea) {
                setErrorMsg((prev) => ({ ...prev, penthouseSlabArea: "Pent house is required" }))
                isValid = false
            }else{
                 setErrorMsg((prev) => ({ ...prev, penthouseSlabArea: "" }))
                 isValid = true
            }
        }else{
              setErrorMsg((prev) => ({ ...prev, penthouseSlabArea: "" }))
             
        }
        if (!projectDetails?.buildArea) {
            setErrorMsg((prev) => ({ ...prev, buildArea: "Build Area is required" }))
            isValid = false
        }
        if (!projectDetails?.totalCost) {
            setErrorMsg((prev) => ({ ...prev, totalCost: "TotalCost is required" }))
            isValid = false
        }
        if (!projectDetails?.status) {
            setErrorMsg((prev) => ({ ...prev, status: "status is required" }))
            isValid = false
        }
        return isValid
    }

    const updateOrAddApi = async (typeofApi) => {
        console.log("update or add api ", typeofApi)
        try {
            if (typeofApi === "add") {
                const body = {
                    ...projectDetails,
                    noOfFloors: projectDetails?.noOfFloors === "" ? null : projectDetails?.noOfFloors,
                    projectCoordinates: projectDetails?.projectLatitude
                        .concat(",")
                        .concat(projectDetails?.projectLongitude),
                    customerId: customerIdFromApi,
                    salesExecutiveId: Number(window.localStorage.getItem("user_Id"))
                }
                console.log("project", body)
                const response = await addProjectService(body)
                if (response.status === "success") {
                    setProjectDetails((values) => ({
                        ...values,
                        projectId: response?.projectId,
                        customerId: response?.customerId
                    }))
                    projectDetailsForPayments({
                        projectId: response?.projectId,
                        projectType: response?.projectType,
                        type: "add"
                    })
                    toast.success("successfully added the project")
                    handleTabs("Payments")
                } else {
                    toast.error("Failed to add the project")
                }
            } else if (typeofApi === "update") {
                const body = {
                    ...projectDetails,
                    noOfFloors: projectDetails?.noOfFloors === "" ? null : projectDetails?.noOfFloors,
                    projectCoordinates: projectDetails?.projectLatitude
                        .concat(",")
                        .concat(projectDetails?.projectLongitude),
                    salesExecutiveId: Number(window.localStorage.getItem("user_Id"))
                }
                // console.log("");
                // console.log(((projectDetailsForPayments !== null) && ((projectDetailsForPayments?.projectType !== projectDetails?.projectType) || (projectDetailsForPayments?.projectAmount !== projectDetails?.totalCost))) ? "add" : "edit");
                const response = await updateProjectService(body)
                if (response.status === "success") {
                    toast.success("Updated Project successfully")
                    // toggleHandler("");
                    projectDetailsForPayments({
                        projectId: response.projectId,
                        projectType: response.projectType,
                        projectAmount: response.projectAmount,
                        type: "edit"
                        // type: ((projectDetailsForPayments !== null) && ((projectDetailsForPayments?.projectType !== projectDetails?.projectType) || (projectDetailsForPayments?.projectAmount !== projectDetails?.totalCost))) ? "add" : "edit"
                    })
                    handleTabs("Payments")
                } else {
                    toast.error("Failed to Update Project")
                }
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    //submit handler :
    const submitHandler = async () => {
        // e.preventDefault()
        console.log("inside the submit handleer")
        if (fieldValidations()) {
            if (type === "add" || type === "newlead") {
                if (projectDetails?.projectId) {
                    updateOrAddApi("update")
                } else {
                    updateOrAddApi("add")
                }
            } else if (type === "edit" || type === "updateproject") {
                if (projectDetails?.projectId) {
                    updateOrAddApi("update")
                } else {
                    updateOrAddApi("add")
                }
            }
        } else {
            if (!projectDetails.noOfFloors || !(projectDetails.noOfFloors > 0 && projectDetails.noOfFloors < 5)) {
                toast.warning("Please give no of floors in between 0 and 5")
            } else {
                toast.error("Please Fill all details ")
            }
        }
    }
    const getProjectById = async () => {
        try {
            const response = await getProjectByCustomerId({ customerId: customerIdFromApi })
            if (response.status === "success") {
                console.log("customer response", response)
                setProjectDetails({ ...response.data })
                console.log(projectDetails,"project Details")
            } else {
                // toast.warning("No data Found");
                console.log("no data found in project")
            }
        } catch (error) {
            console.log("error", error)
            toast.error("failed to fetch the project data")
        }
    }

    // const getFloorList = async () => {
    //     try {
    //         const response = await DropDownServices.getFloorListService({ projectTypeId: projectDetails?.projectType });
    //         if (response.status === "success") {
    //             console.log("result", response.data);
    //             setFloorOptions(response.data);

    //         }
    //         else {
    //             setFloorOptions([]);
    //             setProjectDetails((prevState) => ({ ...prevState, noOfFloors: "" }));
    //         }

    //     } catch (error) {
    //         console.log("error", error);
    //         setFloorOptions([]);
    //     }
    // }

    useEffect(() => {
        if (type === "edit" || type === "updateproject") {
            console.log(projectDetails)
            // if(projectDetails.length>0){

                getProjectById()
                
            // }

        } else if (type === "newlead") {
            setProjectDetails((values) => ({ ...values, city: newLead?.area }))
        }
    }, [])

    useEffect(() => {
        dispatch(getStatusListType("Project"))
        dispatch(GETPROJECTTYPELIST())
    }, [])

    useEffect(() => {
        if (projectDetails?.penthouse === "No") {
            setProjectDetails((prevState) => ({ ...prevState, penthouseSlabArea: "" }))
        }
    }, [projectDetails?.penthouse])
  
    useEffect(() => {
        if (projectDetails?.projectType !== "Others") {
            setProjectDetails((prevState) => ({ ...prevState, noOfFloors: "" }))
        } 
    }, [projectDetails?.projectType])

    // useEffect(() => {
    //     if (projectDetails?.projectType) {
    //         getFloorList();
    //     }

    // }, [projectDetails?.projectType])

    console.log("project details", projectDetails)
    const isValid = 
    projectDetails.projectName.trim() !== "" &&
    projectDetails.contractType !== "" &&
    projectDetails.projectLocation.trim() !== "" &&
    projectDetails.pinCode.trim() !== "" &&
    projectDetails.city.trim() !== "" &&
    projectDetails.projectType !== "" &&
    projectDetails.noOfFloors !== null &&
    projectDetails.plotArea.trim() !== "" &&
    projectDetails.slabArea.trim() !== "" &&
    projectDetails.buildArea.trim() !== "" &&
     projectDetails.totalCost !== "" &&
    (projectDetails.penthouse === "No" || projectDetails.penthouseSlabArea.trim() !== "") &&
    projectDetails.projectLatitude.trim() !== "" &&
    projectDetails.projectLongitude.trim() !== "";
  

    return (
        <div className="contentBody detail">
            <div className="row">
                <div className="col-md-8" style={{ margin: "auto" }}>
                    <div className="whCard">
                        <div className="cardHeader">
                            <span>Projects Details</span>
                            <div className="d-flex customer-details-btn">
                                <select
                                    value={projectDetails.status}
                                    name="status"
                                    className={`selectStatus ${
                                        (projectDetails.status === "Active" && "Active") ||
                                        (projectDetails.status === "Inactive" && "Inactive") ||
                                        (projectDetails.status === "Deleted" && "Deleted")
                                    }`}
                                    onChange={ProjectHandlerChange}
                                >
                                    <option value={"Draft"}>Draft</option>
                                </select>
                                <Tooltip title="Customer Details">
                                    <button className="btn whBtnPrimary" onClick={() => handleTabs("Customer")}>
                                        <BiLeftArrowAlt size="25px" className="" />
                                        Back
                                    </button>
                                </Tooltip>
                                <button onClick={() => submitHandler()} className="btn saveBtnclose" disabled={!isValid}>
                                    Save & next
                                </button>
                            </div>
                        </div>
                        <div className="cardBody">
                            <div className="row">
                                <div className="col">
                                    <label>Project Name *</label>
                                    <div>
                                        <input
                                            type="text"
                                            value={projectDetails.projectName}
                                            name="projectName"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectName && "errorInput"}`}
                                            placeholder="Enter Project Name"
                                        />
                                    </div>
                                    {errorMsg?.projectName ? (
                                        <small className="emailExist">{errorMsg?.projectName}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <label>Contract Type *</label>
                                    <div>
                                        <select
                                            value={projectDetails.contractType}
                                            onChange={ProjectHandlerChange}
                                            className={`form-select ${errorMsg?.contractType && "errorInput"}`}
                                            name="contractType"
                                        >
                                            <option hidden>select contract type</option>
                                            <option value="Caution Deposit">Caution Deposit</option>
                                            <option value="Signed">Signed</option>
                                        </select>
                                    </div>
                                    {errorMsg?.contractType ? (
                                        <small className="emailExist">{errorMsg?.contractType}</small>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div>
                                        <label>Project Location *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            value={projectDetails.projectLocation}
                                            name="projectLocation"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectLocation && "errorInput"}`}
                                            placeholder="Enter Project Location"
                                        />
                                        {/* <GooglePlacesAutocomplete/> */}
                                    </div>
                                    {errorMsg?.projectLocation ? (
                                        <small className="emailExist">{errorMsg?.projectLocation}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Latitude *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.projectLatitude}
                                            name="projectLatitude"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectLatitude && "errorInput"}`}
                                            placeholder="Enter Project Coordinates"
                                        />
                                    </div>
                                    {errorMsg?.projectLatitude ? (
                                        <small className="emailExist">{errorMsg?.projectLatitude}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Longitude *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.projectLongitude}
                                            name="projectLongitude"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.projectLongitude && "errorInput"}`}
                                            placeholder="Enter Project Longtitude"
                                        />
                                    </div>
                                    {errorMsg?.projectLongitude ? (
                                        <small className="emailExist">{errorMsg?.projectLongitude}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>PinCode *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            value={projectDetails.pinCode}
                                            name="pinCode"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.pinCode && "errorInput"}`}
                                            placeholder="Enter PinCode"
                                        />
                                    </div>
                                    {errorMsg?.pinCode ? (
                                        <small className="emailExist">{errorMsg?.pinCode}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>City *</label>
                                    </div>
                                    <div>
                                        <select
                                            value={projectDetails.city}
                                            onChange={ProjectHandlerChange}
                                            className={`form-select ${errorMsg?.city && "errorInput"}`}
                                            name="city"
                                        >
                                            <option hidden>select City</option>
                                            <option value="Hyderabad">Hyderabad</option>
                                            <option value="Chennai">Chennai</option>
                                        </select>
                                    </div>
                                    {errorMsg?.city ? <small className="emailExist">{errorMsg?.city}</small> : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Type Of Project *</label>
                                    </div>
                                    <div>
                                        <select
                                            value={projectDetails.projectType}
                                            onChange={ProjectHandlerChange}
                                            className={`form-select ${errorMsg?.projectType && "errorInput"}`}
                                            name="projectType"
                                        >
                                            <option hidden>select Project</option>
                                            {projectTypeList?.map((project) => {
                                                return <option value={project?.value}>{project?.label}</option>
                                            })}
                                        </select>
                                    </div>
                                    {errorMsg?.projectType ? (
                                        <small className="emailExist">{errorMsg?.projectType}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Number Of Floors *</label>
                                    </div>
                                    <div>
                                        {
                                            // floorOptions?.length === 0 ?
                                            <input
                                                type="number"
                                                value={projectDetails.noOfFloors}
                                                name="noOfFloors"
                                                onChange={ProjectHandlerChange}
                                                className={`form-control ${errorMsg?.noOfFloors && "errorInput"}`}
                                                placeholder="No of floors between 0 and 5"
                                                onWheel={(e) => e.currentTarget.blur()}
                                                disabled={projectDetails?.projectType !== "Others" ? true : false}
                                                // disabled={true}
                                            />
                                            // : (
                                            //     <select
                                            //         className="form-select"
                                            //         name='noOfFloors'
                                            //         value={projectDetails.noOfFloors}
                                            //         onChange={ProjectHandlerChange}
                                            //         placeholder="Enter No of Floors"
                                            //     >
                                            //         <option hidden>Choose floors</option>
                                            //         {
                                            //             floorOptions?.map((floor) => {
                                            //                 return (
                                            //                     <option value={floor.value}>{floor.label}</option>
                                            //                 )
                                            //             })
                                            //         }
                                            //     </select>
                                            // )
                                        }
                                    </div>
                                    {errorMsg?.noOfFloors ? (
                                        <small className="emailExist">{errorMsg?.noOfFloors}</small>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Plot Area(Sq yd)*</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.plotArea}
                                            name="plotArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.plotArea && "errorInput"}`}
                                            placeholder="Enter Plot Dimensions"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.plotArea ? (
                                        <small className="emailExist">{errorMsg?.plotArea}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Slab Area (sq.ft) *</label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.slabArea}
                                            name="slabArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.slabArea && "errorInput"}`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Slab Area"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.slabArea ? (
                                        <small className="emailExist">{errorMsg?.slabArea}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label>Penthouse</label>
                                    </div>
                                    <div>
                                        <select
                                            value={projectDetails.penthouse}
                                            onChange={ProjectHandlerChange}
                                            className={`form-select`}
                                            name="penthouse"
                                        >
                                            <option hidden>Need Penthouse ?</option>
                                            <option value="yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col">
                                    <div>
                                        <label>Penthouse Area (sq.ft) </label>
                                    </div>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.penthouseSlabArea}
                                            name="penthouseSlabArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Slab Area"
                                            onWheel={(e) => e.currentTarget.blur()}
                                            disabled={projectDetails?.penthouse === "No" ? true : false}
                                        />
                                    </div>
                                    {errorMsg?.penthouseSlabArea ? (
                                        <small className="emailExist">{errorMsg?.penthouseSlabArea}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Build Up Area (sq.ft)*</label>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails?.buildArea}
                                            name="buildArea"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.buildArea && "errorInput"}`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Build Area"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.buildArea ? (
                                        <small className="emailExist">{errorMsg?.buildArea}</small>
                                    ) : null}
                                </div>
                                <div className="col">
                                    <label>Total Cost *</label>
                                    <div>
                                        <input
                                            type="number"
                                            value={projectDetails.totalCost}
                                            name="totalCost"
                                            onChange={ProjectHandlerChange}
                                            className={`form-control ${errorMsg?.totalCost && "errorInput"}`}
                                            onKeyDown={blockInvalidChar}
                                            placeholder="Enter Total cost"
                                            onWheel={(e) => e.currentTarget.blur()}
                                        />
                                    </div>
                                    {errorMsg?.totalCost ? (
                                        <small className="emailExist">{errorMsg?.totalCost}</small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Permissions</label>
                                    <div className="row">
                                        <div className="col-3"> Type</div>
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6 text-center"> Yes</div>
                                                <div className="col-6 text-center">No</div>
                                            </div>
                                        </div>

                                        <div className="col-3 text-center">Upload</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3"> Government</div>
                                        <div className="col-6">
                                            <Radio.Group
                                                name="government"
                                                onChange={ProjectHandlerChange}
                                                className="row"
                                                value={projectDetails?.permissions[0]}
                                                style={{ display: "flex" }}
                                            >
                                                <div className="col-6 text-center">
                                                    <Radio value={"yes"} />
                                                </div>
                                                <div className="col-6 text-center">
                                                    <Radio value={"No"} />
                                                </div>
                                            </Radio.Group>
                                        </div>
                                        <div className="col-3 text-center">
                                            <Upload disabled={projectDetails?.permissions[0]}>
                                                <BsUpload />
                                            </Upload>
                                        </div>
                                        <div className="upload documents"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails

{
    /* <option hidden>select Project</option>
                                                <option>Apartment</option>
                                                <option>Commerical</option>
                                                <option>Duplex Villa</option>
                                                <option>Residential</option>
                                                <option>Triplex</option>
                                                <option>Villa</option> */
}
{
    /* <div className='row'>
                                        <div className="col-3"> Electricity</div>
                                        <div className='col-6'>
                                            <Radio.Group name="electricity" onChange={ProjectHandlerChange} className='row' value={projectDetails?.permissions[1]} style={{ display: "flex" }}>
                                                <div className='col-6 text-center'><Radio value={"yes"} /></div>
                                                <div className='col-6 text-center'><Radio value={"No"} /></div>
                                            </Radio.Group>
                                        </div>
                                        <div className='col-3 text-center'>
                                            <Upload disabled={projectDetails?.permissions[1]}>
                                                <BsUpload />
                                            </Upload>
                                        </div>
                                        <div className='upload documents'></div>
                                    </div> */
}

{
    /* <option value="G">G</option>
                                            <option value="G + 1">G + 1</option>
                                            <option value="G + 2">G + 2</option>
                                            <option value="G + 3">G + 3</option>
                                            <option value="G + 4">G + 4</option>
                                            <option value="G + 5">G + 5</option> */
}
{
    /* {statusList && statusList.map((item, index) => (
                                        <option key={index} value={item.status}>{item.status}</option>
                                    ))} */
}
