import { DatePicker, Upload } from 'antd'
import React from 'react'
import { CloudUploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import "./AddTask.css";
import { disabledDate } from '../../../../../Utilities/helpers';

const AddTask = (
    {
        leadStatus,
        handleLeadActivities
    }
) => {
   console.log("lead status----",moment(leadStatus?.taskStartDate).format("YYYY-MM-DD"),moment(leadStatus?.taskStartDate).format("YYYY-MM-DD"));
    const disabledCustomized = (current) => {
        console.log("current",current);
        return current && current.isBefore(moment(leadStatus?.taskStartDate).add(1, 'day'));
    }
    return (
        <>
            <div className='row'>
                <div className='col-6'>
                    <label> Start Date</label>
                    <DatePicker
                        onChange={handleLeadActivities("taskStartDate")}
                        format={"YYYY-MM-DD"}
                        value={leadStatus?.taskStartDate ? moment(moment(leadStatus?.taskStartDate).format("YYYY-MM-DD")) : ""}
                        disabledDate={disabledDate}
                    />
                </div>
                <div className='col-6'>
                    <label>Due Date</label>
                    <DatePicker
                        onChange={handleLeadActivities("taskEndDate")}
                        format={"YYYY-MM-DD"}
                        value={leadStatus?.taskEndDate && moment(moment(leadStatus?.taskEndDate).format("YYYY-MM-DD"))}
                        disabled={leadStatus?.taskStartDate ? false : true}
                        disabledDate={disabledCustomized}
                    />
                </div>
            </div>
            <div className='row'>
                <label>Upload Images</label>
                <Upload
                    name="avatar"
                    listType="picture-card"
                >
                    <div className='upload-wrapper'>
                        <CloudUploadOutlined />
                        click to upload
                    </div>
                </Upload>
            </div>
        </>
    )
}

export default AddTask;