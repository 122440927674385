import React, { useState, useEffect } from "react"
import "./CustomerDetails.css"
import "./CreateCustomers.scss"
import { AddCustomerService, UpdtaeCustomersDataService } from "../../../../../Service/SeCustomersServices"
import { toast } from "react-toastify"
import { blockInvalidChar, checkEmail, EmailErrorClear } from "../../../../../Utilities/helpers"
import { useDispatch, useSelector } from "react-redux"
import { customerLoader } from "../../../../../Features/Slices/CustomersSlice"
import { getStatusListType } from "../../../../../Features/Saga/getStatusList"
import { statusListApi } from "../../../../../Service/CommonDropdownApiCalls/commonDropdownApiCalls"
import Checkbox from "antd/lib/checkbox/Checkbox"
import { event } from "jquery"

const emailValidate =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

function CustomerDetails({
    type,
    userId,
    toggleHandler,
    newLead,
    handleTabs,
    setCustomerIdFromApi,
    customerIdFromApi
}) {
    console.log("cusomerid from api", customerIdFromApi, newLead)
    const [customerDetails, setCustomerDetails] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        Aadhar: "",
        panCard: "",
        residentialAddress: "",
        maillingAddress: "",
        status: "Active",
        sameAddress:false,
        checkedBox:false
    })
    const [emailError, setEmailError] = useState(false)
    const [checkVar, setCheckVar] = useState(false)
    const [statusList, setStatusList] = useState([])

    const dispatch = useDispatch()
    const { customerDataById, loading } = useSelector((state) => state.Customers)
    const [errorMsg, setErrorMsg] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        Aadhar: "",
        panCard: "",
        residentialAddress: "",
        maillingAddress: ""
    })
    const [checkedBox,setCheckedBox]=useState(false)
    // console.log("customer by id",customerDataById,loading);
    // const statusList = useSelector((state) => state.getStatusListSlice.status);
    // console.log("statusList:", statusList, newLead);

    // console.log('type:', type, "userId :", userId);

    /* save the field values in local state*/
    const handlerChange = (e) => {
        const { name, value } = e.target
        setErrorMsg((prev) => ({ ...prev, [name]: "" }))
        if (name === "Aadhar") {
            setCustomerDetails((prevState) => ({ ...prevState, [name]: value.slice(0, 12) }))
        } else if (name === "phone" || name === "panCard") {
            setCustomerDetails((prevState) => ({ ...prevState, [name]: value.slice(0, 10) }))
        } else {
            setCustomerDetails((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }
        console.log("event",e)
        if (name=="sameAddress"){
            setCustomerDetails((prev) => ({ ...prev, sameAddress: e?.target?.checked }))
            if (e?.target?.checked) {
                setCustomerDetails((prev) => ({ ...prev, maillingAddress: prev.residentialAddress }))
              
                
            } else {
                setCustomerDetails((prev) => ({ ...prev, maillingAddress: "" }))
                
            }
        }
    }

    const updateOrAddApi = async (typeofApi, buttonType, customerDetails) => {
        console.log("values in update or add api", typeofApi, buttonType, customerDetails)
        try {
            if (typeofApi === "add") {
                const response = await AddCustomerService(customerDetails)
                // console.log('addcustomer ::::::', response);
                if (response.status === "success" && response.data.add_customers === true) {
                    toast.success("Customer data is Added succesfully")
                    setCustomerIdFromApi(response.data.userId)
                    // handleTabs("Project");
                    // toggleHandler("")
                    buttonType === "save&next" ? handleTabs("Project") : toggleHandler("")
                } else {
                    toast.error("Failed to Add customer")
                }
            } else if (typeofApi === "update") {
                const response = await UpdtaeCustomersDataService(customerDetails)
                console.log("CustomerDataById::", response)
                if (response?.status === "success") {
                    toast.success(response.data.message)
                    setCustomerIdFromApi(response.customerId)
                    buttonType === "save&next" ? handleTabs("Project") : toggleHandler("")
                } else {
                    toast.error("failed to  Update Customer")
                }
            }
        } catch (error) {
            console.log("error", error)
            toast.error("something went wrong")
        }
    }

    /*submit handler for api hitting*/
    const submitHandler = async (check) => {
        // e.preventDefault();

        setCheckVar(true)
        if (validateForm()) {
            if (type === "edit" || customerIdFromApi || type === "updateproject") {
                // alert("ok");
                const CustomerDataById = {
                    aadhar: customerDetails.Aadhar,
                    email: customerDetails.email,
                    firstName: customerDetails.firstName,
                    lastName: customerDetails.lastName,
                    mobile: customerDetails.phone,
                    pan: customerDetails.panCard,
                    residentialAddress: customerDetails.residentialAddress,
                    userId: customerDataById?.userid,
                    mailingAddress: customerDetails.maillingAddress,
                    status: customerDetails.status,
                    customerId: customerDataById?.userid || customerIdFromApi,
                    userId: Number(window.localStorage.getItem("user_Id")),
                    setCheckedBox:customerDetails.maillingAddress===customerDetails.residentialAddress
                }

                updateOrAddApi("update", check, CustomerDataById)
            } else if (type === "newlead") {
                const customerData = {
                    FirstName: customerDetails.firstName,
                    LastName: customerDetails.lastName,
                    PhoneNumber: customerDetails.phone,
                    Email: customerDetails.email,
                    Aadhar: customerDetails.Aadhar,
                    PAN: customerDetails.panCard,
                    ResidentialAddress: customerDetails.residentialAddress,
                    MaillingAddress: customerDetails.maillingAddress,
                    Status: customerDetails.status,
                    leadId: newLead?.otpId || newLead?.leadId,
                    userId: Number(window.localStorage.getItem("user_Id")),
                    
                }
                updateOrAddApi("add", check, customerData)
            } else {
                const customerData = {
                    FirstName: customerDetails.firstName,
                    LastName: customerDetails.lastName,
                    PhoneNumber: customerDetails.phone,
                    Email: customerDetails.email,
                    Aadhar: customerDetails.Aadhar,
                    PAN: customerDetails.panCard,
                    ResidentialAddress: customerDetails.residentialAddress,
                    MaillingAddress: customerDetails.maillingAddress,
                    Status: customerDetails.status,
                    leadId: null,
                    userId: Number(window.localStorage.getItem("user_Id"))
                }
                updateOrAddApi("add", check, customerData)
                // console.log("CustomersData :::", customerData);
                // const response = await AddCustomerService(customerData);
                // console.log('addcustomer ::::::', response);
                // if (response.status === "success" && response.data.add_customers === true) {
                //     toast.success('user data is submitted succesfully');
                //     // toggleHandler("")
                //     // handleTabs("Project");
                //     check === "save&next" ? handleTabs("Project") : toggleHandler("")
                // } else {
                //     toast.error('Failed to Add customer');

                // }
            }
        } else {
            toast.error("Please fill the required fields")
        }
    }

    /* Function for GetCustomersDataById and add new lead from my leads screen*/
    const GetCustomerDataById = () => {
        if (type === "edit" || type === "updateproject") {
            const {
                aadhar,
                firstname,
                lastname,
                mailing_address,
                mobile,
                pan_card,
                status,
                submitted_time,
                userid: customerId,
                email,
                temp_address
            } = customerDataById

            setCustomerDetails({
                firstName: firstname,
                lastName: lastname,
                phone: mobile,
                email: email,
                Aadhar: aadhar,
                panCard: pan_card,
                residentialAddress: temp_address,
                maillingAddress: mailing_address,
                status: status,
                setCheckedBox:temp_address===mailing_address
            })
        }
        if (type === "newlead") {
            const { email, mobile, name,lastName } = newLead
            const { leadMobile, leadName, leadEmail,leadLastName } = newLead
            console.log(newLead,"newLead")
            const [firstName, last] = name ? name.split(" ") : [null, lastName];

            setCustomerDetails({
                firstName: firstName || leadName,
                lastName: last || leadLastName,
                phone: mobile || leadMobile,
                email: email || leadEmail,
                Aadhar: "",
                panCard: "",
                residentialAddress: "",
                maillingAddress: "",
                status: "Active",
                setCheckedBox:""
            })
        }
    }

    useEffect(async () => {
        // dispatch(getStatusListType("Customer"))

        //  get staus list api :
        const statusListResponse = await statusListApi()
        console.log("statusListResponse:", statusListResponse)
        setStatusList(statusListResponse.data)
    }, [])
    console.log("email error:", emailError)


    useEffect(() => {
        if (Object.keys(customerDataById).length > 0 || newLead ) {
            GetCustomerDataById();  
            console.log("check it v");
        }
    }, [customerDataById,newLead]);

   

    // Form Validation:
    const validateForm = () => {
        let IsValid = true
        if (!customerDetails.firstName) {
            setErrorMsg((prev) => ({ ...prev, ...prev, firstName: "First Name is required" }))
            IsValid = false
        }
        if (!customerDetails.lastName) {
            setErrorMsg((prev) => ({ ...prev, ...prev, lastName: "Last Name is required" }))
            IsValid = false
        }
        if (customerDetails.phone.length !== 10) {
            setErrorMsg((prev) => ({ ...prev, ...prev, phone: "Enter 10 digits Mobile number" }))
            IsValid = false
        }
        if (!emailValidate.test(customerDetails?.email)) {
            setErrorMsg((prev) => ({ ...prev, ...prev, email: "Enter Valid Email" }))
            IsValid = false
        }
        if (customerDetails.Aadhar.length !== 12) {
            setErrorMsg((prev) => ({ ...prev, ...prev, Aadhar: "Enter 12 digits Aadhaar number" }))
            IsValid = false
        }
        if (!customerDetails.panCard || customerDetails.panCard.length<6) {
            setErrorMsg((prev) => ({ ...prev, panCard: "Enter 10 digits PAN number" }))
            IsValid = false
        }
        if (!customerDetails.residentialAddress) {
            setErrorMsg((prev) => ({ ...prev, residentialAddress: "Address is required" }))
            IsValid = false
        }
        // if (!customerDetails.maillingAddress) {
        //     IsValid = false
        // }

        return IsValid
    }
    console.log("customerDetails", customerDetails)

const isValid = 
  (customerDetails.firstName?.trim() || "") !== "" &&
  (customerDetails.lastName?.trim() || "") !== "" &&
  (customerDetails.phone?.trim() || "") !== "" &&
  (customerDetails.email?.trim() || "") !== "" &&
  (customerDetails.Aadhar?.trim() || "") !== "" &&
  (customerDetails.panCard?.trim() || "") !== "" &&
  (customerDetails.residentialAddress?.trim() || "") !== "" &&
  (customerDetails.sameAddress || (customerDetails.maillingAddress?.trim() || "") !== "");

    return (
        <div className="contentBody detail">
            {/* {emailError && toast.error( "Email already Exist")} */}
            <div>
                <div className="row">
                    <div className="col-md-8" style={{ margin: "auto" }}>
                        <div className="whCard">
                            <div className="cardHeader">
                                <span>Customers Details</span>
                                <div className="d-flex customer-details-btn">
                                    <select
                                        value={customerDetails.status}
                                        name="status"
                                        className={`selectStatus ${
                                            (customerDetails.status === "Active" && "Active") ||
                                            (customerDetails.status === "Inactive" && "Inactive") ||
                                            (customerDetails.status === "Deleted" && "Deleted")
                                        }`}
                                        onChange={handlerChange}
                                    >
                                        {statusList &&
                                            statusList.map((item, index) => (
                                                <option key={index} value={item.status}>
                                                    {item.status}
                                                </option>
                                            ))}
                                    </select>
                                    {/* <button onClick={() => submitHandler("save&close")} className="btn saveBtnclose">Save & Close</button> */}
                                    <button onClick={() => submitHandler("save&next")} className="btn saveBtn" disabled={!isValid}>
                                        Save & Next
                                    </button>
                                </div>
                            </div>
                            <div className="cardBody">
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>First Name *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={customerDetails.firstName}
                                                onChange={handlerChange}
                                                placeholder="Enter First Name"
                                                className={`form-control ${errorMsg?.firstName && "errorInput"}`}
                                            />
                                        </div>
                                        {errorMsg?.firstName ? (
                                            <small className="emailExist">{errorMsg?.firstName}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>Last Name *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={customerDetails.lastName}
                                                onChange={handlerChange}
                                                placeholder="Enter Last Name"
                                                className={`form-control ${errorMsg?.lastName && "errorInput"}`}
                                            />
                                        </div>
                                        {errorMsg?.lastName ? (
                                            <small className="emailExist">{errorMsg?.lastName}</small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>Mobile Number *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                name="phone"
                                                value={customerDetails.phone}
                                                onChange={handlerChange}
                                                className={`form-control ${errorMsg?.phone && "errorInput"}`}
                                                onKeyDown={blockInvalidChar}
                                                placeholder="Enter Mobile Number"
                                            />
                                        </div>
                                        {errorMsg.phone ? (
                                            <small className="emailExist">{errorMsg?.phone}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>Email Id *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="email"
                                                name="email"
                                                value={customerDetails.email}
                                                onChange={handlerChange}
                                                className={`form-control ${errorMsg?.email && "errorInput"}`}
                                                placeholder="Enter Email Id"
                                                onBlur={() => {
                                                    checkEmail(customerDetails.email, setEmailError)
                                                }}
                                            />
                                        </div>
                                        {errorMsg?.email ? (
                                            <small className="emailExist">{errorMsg?.email}</small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <label>Aadhaar *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                name="Aadhar"
                                                value={customerDetails.Aadhar}
                                                onChange={handlerChange}
                                                onKeyDown={blockInvalidChar}
                                                className={`form-control ${errorMsg?.Aadhar && "errorInput"}`}
                                                placeholder="Enter Aadhar Number"
                                            />
                                        </div>
                                        {errorMsg?.Aadhar ? (
                                            <small className="emailExist">{errorMsg.Aadhar}</small>
                                        ) : null}
                                    </div>
                                    <div className="col">
                                        <div>
                                            <label>PAN Card *</label>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="panCard"
                                                value={customerDetails.panCard}
                                                onChange={handlerChange}
                                                className={`form-control ${errorMsg?.panCard && "errorInput"}`}
                                                placeholder="Enter Pan Number"
                                            />
                                        </div>
                                        {errorMsg?.panCard ? (
                                            <small className="emailExist">{errorMsg.panCard}</small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="customer-details-form-group">
                                            <div>
                                                <label>Residential Address *</label>
                                            </div>
                                            <div>
                                                <textarea
                                                    name="residentialAddress"
                                                    value={customerDetails.residentialAddress}
                                                    onChange={handlerChange}
                                                    className={`form-control ${
                                                        errorMsg?.residentialAddress && "errorInput"
                                                    }`}
                                                    rows="3"
                                                    placeholder="Enter Residential Address"
                                                />
                                            </div>
                                            {errorMsg?.residentialAddress ? (
                                                <small className="emailExist">{errorMsg.residentialAddress}</small>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="col adrressCheckbox">
                                        <Checkbox name="sameAddress" onChange={handlerChange} checked={customerDetails.residentialAddress&&customerDetails.residentialAddress===customerDetails.maillingAddress}>
                                            Same as Residential Address
                                        </Checkbox>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="customer-details-form-group">
                                            <div>
                                                <label>Mailing Address</label>
                                            </div>
                                            <div>
                                                <textarea
                                                    name="maillingAddress"
                                                    value={customerDetails.maillingAddress}
                                                    onChange={handlerChange}
                                                    className=""
                                                    rows="3"
                                                    placeholder="Enter Mailing Address"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerDetails

// if(!customerDetails.lastName.length){
//     IsValid = false
// }

{
    /* <option value="Active">Active</option>
                                            <option value="Inactive">InActive</option>
                                            <option value="Deleted">Delete</option> */
}

{
    /* <form> */
}
{
    /* </form> */
}
